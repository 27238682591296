import { buildProperty, buildSchema } from "@camberi/firecms";

export const documentSchema = buildSchema({
  name: "Documents",
  properties: {
    fileName: {
      title: "File Name",
      dataType: "string",
    },
    file: buildProperty({
      title: "File",
      dataType: "string",
      config: {
        storageMeta: {
					// TODO: custom preview in CMS for file documents, since there is no media type for it?
          // TODO: pass a function to get a dynamic path based on the homeId
          storagePath: "documents",
          acceptedFiles: ["application/pdf"],
        },
      },
    }),
  },
});