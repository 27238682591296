import { buildSchema } from "@camberi/firecms";
// import { HomebuyerInvitationStatus } from "../models/homebuyer_model";

export const homebuyerSchema = buildSchema({
  name: "Homebuyer",
  properties: {
		displayName: {
			title: "Name",
			dataType: "string",
		},
		email: {
			title: "Email",
			dataType: "string",
			validation: { email:true },
		},
		phoneNumber: {
			title: "Phone Number",
			dataType: "string",
		},
    photoURL: {
      title: "Photo",
      dataType: "string",
      config: {
        url: "image",
      },
			validation: { url:true },
    },
    homes: {
      title: "Homes",
      dataType: "array",
      of: {
        dataType: "reference",
        collectionPath: "homes",
        previewProperties: ["streetAddress", "communityData"]
      },
    }
  }
});