import { buildCollection } from "@camberi/firecms";
import { communitySearchDelegate } from "../aloglia";
import { announcementSchema } from "../schemas/announcement_schema";
import { communitySchema } from "../schemas/community_schema";
import { documentSchema } from "../schemas/document_schema";

export const communityCollection = buildCollection({
  relativePath: "communities",
  schema: communitySchema,
  properties: ["name", "division"],
  excludedProperties: ["divisionData"],
  group: "Content",
  name: "Communities",
  textSearchDelegate: communitySearchDelegate,
  permissions: ({ user }) => ({
    edit: true,
    create: true,
    delete: true,
  }),
  subcollections: [
    buildCollection({
      relativePath: "documents",
      schema: documentSchema,
      name: "Documents",
      permissions: ({ user }) => ({
        edit: true,
        create: true,
        delete: true,
      }),
    }),
    buildCollection({
      relativePath: "annoucements",
      schema: announcementSchema,
      name: "Announcements",
      permissions: ({ user }) => ({
        edit: true,
        create: true,
        delete: true,
      }),
    }),
  ],
});
