import { buildSchema } from "@camberi/firecms";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { HomeDocumentData } from "../models/home_model";

export const homeSchema = buildSchema<Partial<HomeDocumentData>>({
  name: "Home",
  properties: {
    streetAddress: {
      title: "Address",
      validation: { required: true },
      dataType: "string",
    },
    community: {
      dataType: "reference",
      title: "Community",
      collectionPath: "communities",
      previewProperties: ["name", "divisionData"],
    },
    homebuyers: {
      title: "Homebuyers",
      dataType: "array",
      of: {
        dataType: "reference",
        collectionPath: "homebuyers",
        previewProperties: ["displayName"],
      }
    },
    city: {
      title: "City",
      dataType: "string",
    },
    stateCode: {
      title: "State Code",
      dataType: "string",
    },
    zipCode: {
      title: "Zip",
      dataType: "string",
    },
    plan: {
      title: "Plan",
      dataType: "string",
    },
    elevation: {
      title: "Elevation",
      dataType: "string",
    },
    lot: {
      title: "Lot Number",
      dataType: "string",
    },
    cover: ({ entityId }) => ({
      title: "Cover Photo",
      dataType: "string",
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: `homes/${entityId || 'tbd'}/cover`,
          acceptedFiles: ["image/*"],
          metadata: {
            cacheControl: "max-age=1000000",
          },
        },
      },
    }),
    coverUrl: {
      dataType: "string",
      title: "Cover Photo Url",
      readOnly: true,
      description: "This field is automatically populated",
      config: {
        url: true,
      },
    },
    communityData: {
      dataType: "map",
      config: {
        field: () => null,
      },
      previewProperties: ["name", "divisionData"],
      properties: {
        uid: {
          dataType: "string",
        },
        name: {
          dataType: "string",
        },
        divisionData: {
          dataType: "map",
          previewProperties: ["name"],
          properties: {
            uid: {
              dataType: "string",
            },
            name: {
              dataType: "string",
            },
          },
        },
      },
    },
  },
  onSaveSuccess: async ({
    values,
    collectionPath,
    id,
  }) => {
    const coverRef = values.cover;
    if (!coverRef) return;

    let coverUrl: string;

    // get download urls for each file, and save them to the document
    try {
      coverUrl = await firebase.storage().ref(coverRef).getDownloadURL();
    } catch (err) {
      console.warn("failed to retrieve download url for media", err);
      return;
    }

    // update document with media urls
    try {
      await firebase.firestore().collection(collectionPath).doc(id).update({ coverUrl });
    } catch(err) {
      console.warn("failed to save coverUrl", err);
    }
  }
});