import { buildCollection } from "@camberi/firecms";
import { homebuyerSearchDelegate } from "../aloglia";
import { homebuyerSchema } from "../schemas/homebuyer_schema";

export const homebuyerCollection = buildCollection({
  relativePath: "homebuyers",
  schema: homebuyerSchema,
  textSearchDelegate: homebuyerSearchDelegate,
  properties: ["displayName", "photoURL", "email", "phoneNumber", "homes"],
  group: "Content",
  name: "Homebuyers",
  permissions: ({ user }) => ({
    edit: true,
    create: true,
    delete: true,
  }),
});
