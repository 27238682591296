import { buildSchema } from "@camberi/firecms";
import { Admin } from "../models/admin_model";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { PostDocumentData } from "../models/post_model";

export const postSchema = buildSchema<Partial<PostDocumentData>>({
  name: "Photo Updates",
  properties: {
    heading: {
      title: "Title",
      dataType: "string",
      validation: {
        required: true,
        min: 1,
        trim: true,
      }
    },
    summary: {
      title: "Description",
      dataType: "string",
      config: {
        multiline: true,
        markdown: true,
      },
      validation: {
        required: true,
        min: 1,
      }
    },
    uploadedBy: {
      title: "Uploaded By",
      dataType: "reference",
      collectionPath: "admins",
      previewProperties: ["displayName"],
    },
    takenAt: {
      title: "Date taken",
      dataType: "timestamp",
      validation: {
        required: true,
      },
    },
    media: ({ collectionPath }) => ({
      title: "Media",
      description: "This fields allows uploading multiple images at once and reordering",
      dataType: "array",
      validation: {
        required: true,
        min: 1,
      },
      oneOf: {
        properties: {
          images: {
            dataType: "array",
            of: {
              dataType: "string",
              config: {
                storageMeta: {
                  mediaType: "image",
                  storagePath: collectionPath,
                  acceptedFiles: ["image/*"],
                  metadata: {
                    cacheControl: "max-age=1000000",
                  },
                },
              },
            },
          },
        }
      },
    }),
    mediaUrls: {
      title: "Media URLs",
      description: "This field is automatically populated",
      dataType: "array",
      readOnly: true,
      of: {
        dataType: "map",
        previewProperties: ["url"],
        properties: {
          ref: {
            dataType: "string",
          },
          url: {
            dataType: "string",
            config: {
              url: true,
            }
          },
        },
      },
    },
  },
  onPreSave: async ({
    values,
    status,
  }) => {
    if (status !== "new" || values.uploadedBy) return values;

    const currentUser = firebase.auth().currentUser;
    if (!currentUser) throw new Error("Unsufficient permissions to send a new message")

    const snapshot = await firebase.firestore().collection("admins").doc(currentUser.uid).withConverter(Admin).get();
    if (!snapshot.exists) throw new Error("Unsufficient permissions to send a new message");

    const res = {
      ...values,
      uploadedBy: snapshot.ref,
    };

    return res;
  },
  onSaveSuccess: async ({
    values,
    collectionPath,
    id,
  }) => {
    // mediaUrls contain long-lived urls that can be directly used by the client to display media files
    const mediaUrls = [] as { ref: string; url: string; }[];

    // get download urls for each file, and save them to the document
    await Promise.all((values.media || [])?.map(async (m) => {
      if (!m) return;

      try {
        await Promise.all(m.value.map(async (ref) => {
          const url = await firebase.storage().ref(ref).getDownloadURL()
          mediaUrls.push({ ref, url });
        }));
      } catch (err) {
        console.warn("failed to retrieve download url for media", err);
      }
    }))

    // update document with media urls
    try {
      await firebase.firestore().collection(collectionPath).doc(id).update({ mediaUrls });
    } catch(err) {
      console.warn("failed to save media urls", err);
    }
  }
});
