import { buildSchema } from "@camberi/firecms";
import { CommunityDocumentData } from "../models/community_model";

export const communitySchema = buildSchema<Partial<CommunityDocumentData>>({
  name: "Community",
  properties: {
    name: {
      title: "Name",
      validation: { required: true },
      dataType: "string",
    },
    division: {
      title: "Division",
      dataType: "reference",
      collectionPath: "divisions",
      previewProperties: ["name"],
      validation: { required: true },
    },
    divisionData: {
      dataType: "map",
      previewProperties: ["name"],
      config: {
        field: () => null,
      },
      properties: {
        uid: {
          dataType: "string",
        },
        name: {
          dataType: "string",
        },
      }
    }
  },
})