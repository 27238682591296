import { buildSchema } from "@camberi/firecms";
import { Admin } from "../models/admin_model";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { MessageDocumentData } from "../models/message_model";

export const messageSchema = buildSchema<Partial<MessageDocumentData>>({
  name: "Message",
  properties: {
    body: {
      title: "Message",
      dataType: "string",
      config: {
        multiline: true,
        markdown: true,
      },
    },
    from: {
      readOnly: true,
      title: "From",
      dataType: "array",
      validation: {
        max: 1,
      },
      oneOf: {
        properties: {
          homebuyer: {
            dataType: "reference",
            collectionPath: "homebuyers",
            previewProperties: ["displayName"],
          },
          admin: {
            dataType: "reference",
            collectionPath: "admins",
            previewProperties: ["displayName"],
          },
        }
      }
    },
    sentAt: {
      title: "Sent At",
      dataType: "timestamp",
      readOnly: true,
    },
    readBy: {
      title: "Read by",
      readOnly: true,
      dataType: "array",
      of: {
        dataType: "reference",
        collectionPath: "homebuyers",
        previewProperties: ["displayName"],
      }
    }
  },
  onPreSave: async ({
    schema,
    collectionPath,
    id,
    values,
    status,
  }) => {
    if (status !== "new" || values.from?.[0]?.value) return values;

    const currentUser = firebase.auth().currentUser;
    if (!currentUser) throw new Error("Unsufficient permissions to send a new message")

    const snapshot = await firebase.firestore().collection("admins").doc(currentUser.uid).withConverter(Admin).get();
    if (!snapshot.exists) throw new Error("Unsufficient permissions to send a new message");

    const res = {
      ...values,
      from: [
        {
          type: "admin",
          value: snapshot.ref,
        },
      ]
    };

    return res;
  },
});

// export const createMessageSchema = buildSchema({
//   name: "New Message",
//   properties: {
//     body: {
//       title: "Body",
//       validation: { required: true },
//       dataType: "string",
//       config: {
//         markdown: true,
//         multiline: true,
//       },
//     },
//   },
//   onPreSave: async ({
//     schema,
//     collectionPath,
//     id,
//     values,
//     status,
//   }) => {
//     if (status !== "new" || (values as any).from?.[0]?.value) return values;

//     const currentUser = firebase.auth().currentUser;
//     if (!currentUser) throw new Error("Unsufficient permissions to send a new message")

//     const snapshot = await firebase.firestore().collection("admins").doc(currentUser.uid).withConverter(Admin).get();
//     if (!snapshot.exists) throw new Error("Unsufficient permissions to send a new message");

//     const res = {
//       ...values,
//       from: [
//         {
//           type: "admin",
//           value: snapshot.ref,
//         },
//       ]
//     };

//     return res;
//   },
// });