import { buildCollection } from "@camberi/firecms";
import { adminSchema } from "../schemas/admin_schema";

export const adminCollection = buildCollection({
  relativePath: "admins",
  schema: adminSchema,
  properties: [
    "displayName",
    "email",
    "phoneNumber",
    "photoURL",
    "roles",
    "divisions",
    "communities",
  ],
  group: "Settings",
  name: "Admins",
  permissions: ({ user }) => ({
    edit: true,
    create: true,
    delete: true,
  }),
});
