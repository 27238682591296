import { buildCollection } from "@camberi/firecms";
import { AdminRole } from "../models/admin_model";
import { conversationSchema } from "../schemas/conversation_schema";
import { messageSchema } from "../schemas/message_schema";
import { receiptSchema } from "../schemas/receipt_schema";

export const conversationCollection = buildCollection({
  relativePath: "conversations",
	// @ts-ignore
  schema: conversationSchema,
  properties: ["home", "homebuyers", "admins", "lastMessageAt"],
  initialSort: ["lastMessageAt", "desc"],
  group: "Content",
  name: "Conversations",
  permissions: ({ authController, user }) => ({
    edit: true,
    create: true,
    delete: authController.extra?.roles?.includes(AdminRole.super),
  }),
  subcollections: [
    buildCollection({
      relativePath: "messages",
      schema: messageSchema,
      properties: ["body", "from", "sentAt"],
      initialSort: ["sentAt", "desc"],
      name: "Messages",
      permissions: ({ user }) => ({
        edit: true,
        create: true,
        delete: true,
      }),
      // extraActions: messageExtraActionBuilder,
      subcollections: [
        buildCollection({
          relativePath: "receipts",
          schema: receiptSchema,
          properties: ["homebuyer", "readAt"],
          initialSort: ["readAt", "desc"],
          name: "Receipts",
          permissions: ({ user }) => ({
            edit: false,
            create: false,
            delete: false,
          }),
        }),
      ],
    }),
  ],
});
