/* eslint-disable @typescript-eslint/no-unused-vars */
// / <reference lib="dom" />
import {
  QueryDocumentSnapshot,
  DocumentSnapshot,
  SnapshotOptions,
  DocumentData,
  FirestoreDataConverter,
  DocumentReference,
} from "./types";

export enum AdminRole {
	// Super Admin can read and write anything
	super = "super",
	// Corporate admin can manage content in specific divisions
	corp = "corp",
	// Sales representatives can manage content in specific communities
	rep = "rep",
}

/**
 * Document data (for use with `DocumentReference.set()`) consists of fields
 * mapped to values.
 */
export type AdminDocumentData = {
	uid: string;
	displayName: string;
	email: string;
  phoneNumber: string;
	photoURL: string;
  roles: AdminRole[];
  divisions?: DocumentReference[];
  communities?: DocumentReference[];
};

export class Admin implements AdminDocumentData {
	public uid!: string;
	public displayName!: string;
	public email!: string;
  public phoneNumber!: string;
	public photoURL!: string;
  public roles!: AdminRole[];
  public divisions?: DocumentReference[];
  public communities?: DocumentReference[];

  constructor(data: AdminDocumentData) {
    Object.assign(this, data);
  }

  toString(): string {
    return `${this.displayName} (${this.roles.join(", ")})`;
  }

  static fromFirestore(
      snapshot: QueryDocumentSnapshot | DocumentSnapshot,
      options?: SnapshotOptions
  ): Admin {
    const data = snapshot.data(options)!;

    return new Admin({
      uid: snapshot.id,
      displayName: data.displayName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      photoURL: data.photoURL,
      roles: data.roles,
      divisions: data.divisions,
      communities: data.communities,
    });
  }

  static toFirestore(admin: Admin): DocumentData {
    return {
      displayName: admin.displayName,
      email: admin.email,
      phoneNumber: admin.phoneNumber,
      photoURL: admin.photoURL,
      roles: admin.roles,
      divisions: admin.divisions,
      communities: admin.communities,
    };
  }
}

// Admin implements firebase.firestore.FirestoreDataConverter<Admin>
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// @ts-ignore
const _: FirestoreDataConverter<Admin> = Admin;
