import { buildSchema } from "@camberi/firecms";

export const announcementSchema = buildSchema({
  name: "Anouncements",
  properties: {
    heading: {
      title: "Title",
      dataType: "string",
    },
    body: {
      title: "Body",
      dataType: "string",
      config: {
        multiline: true,
        markdown: true,
      },
    },
  }
});
