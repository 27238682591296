import React from "react";

import {
  Authenticator,
  CMSApp,
  NavigationBuilder,
  NavigationBuilderProps,
} from "@camberi/firecms";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/functions";

import "typeface-rubik";
import "typeface-space-mono";

import { firebaseConfig } from "./firebaseConfig";

import { Admin } from "./models/admin_model";

import { divisionCollection } from "./collections/division_collection";
import { communityCollection } from "./collections/community_collection";
import { homeCollection } from "./collections/home_collection";
import { homebuyerCollection } from "./collections/homebuyer_collection";
import { conversationCollection } from "./collections/conversation_collection";
import { warrantyDocumentCollection } from "./collections/warranty_document_collection";
import { adminCollection } from "./collections/admin_collection";

const signInOptions = [
  {
    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
    signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
    requireDisplayName: true,
  },
  {
    provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  },
];

const onFirebaseInit = () => {
  if (typeof window === "undefined") return;

  // connect to local firebase emulator suite if running on localhost
  if (window.location.hostname === "localhost") {
    firebase.firestore().useEmulator("localhost", 8080);
    firebase.auth().useEmulator("http://localhost:9099/");
    firebase.storage().useEmulator("localhost", 9199);
    firebase.functions().useEmulator("localhost", 5001);
  }
};

export default function App() {
  const navigation: NavigationBuilder = async ({
    user,
    authController,
  }: NavigationBuilderProps) => {
    if (user?.uid) {
      const doc = await firebase
        .firestore()
        .collection("admins")
        .withConverter(Admin)
        .doc(user.uid)
        .get();

      const admin = doc.data();

      if (admin) {
        authController.setExtra({
          roles: admin.roles,
          divisions: admin.divisions,
          communities: admin.communities,
        });
      }
    }

    return {
      collections: [
        divisionCollection,
        communityCollection,
        homeCollection,
        homebuyerCollection,
        conversationCollection,
        warrantyDocumentCollection,
        adminCollection,
      ],
    };
  };

  const myAuthenticator: Authenticator = (user?: firebase.User) => {
    console.log("Allowing access to", user?.email);
    return true;
  };

  return (
    <CMSApp
      name={"William Ryan Homes"}
      authentication={myAuthenticator}
      navigation={navigation}
      firebaseConfig={firebaseConfig}
      signInOptions={signInOptions}
      onFirebaseInit={onFirebaseInit}
    />
  );
}
