import { buildCollection } from "@camberi/firecms";
import { announcementSchema } from "../schemas/announcement_schema";
import { divisionSchema } from "../schemas/division_schema";
import { divisionSearchDelegate } from "../aloglia";
import { AdminRole } from "../models/admin_model";

export const divisionCollection = buildCollection({
  relativePath: "divisions",
  schema: divisionSchema,
  properties: ["name"],
  group: "Content",
  name: "Divisions",
  textSearchDelegate: divisionSearchDelegate,
  permissions: ({ user, entity, authController }) => ({
    edit: authController.extra?.roles?.includes(AdminRole.super),
    create: authController.extra?.roles?.includes(AdminRole.super),
    delete: authController.extra?.roles?.includes(AdminRole.super),
  }),
  subcollections: [
    buildCollection({
      relativePath: "annoucements",
      schema: announcementSchema,
      name: "Announcements",
      permissions: ({ user, entity, authController }) => ({
        edit: true,
        create: true,
        delete: true,
      }),
    }),
  ],
});