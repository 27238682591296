import { buildCollection } from "@camberi/firecms";
import { homeSearchDelegate } from "../aloglia";
import { documentSchema } from "../schemas/document_schema";
import { homeSchema } from "../schemas/home_schema";
import { postSchema } from "../schemas/post_schema";

export const homeCollection = buildCollection({
  relativePath: "homes",
	// @ts-ignore
  schema: homeSchema,
  properties: ["streetAddress", "community", "homebuyers", "cover"],
  group: "Content",
  name: "Homes",
  textSearchDelegate: homeSearchDelegate,
  permissions: ({ user }) => ({
    edit: true,
    create: true,
    delete: true,
  }),
  subcollections: [
    buildCollection({
      relativePath: "posts",
      properties: ["heading", "summary", "media", "takenAt", "uploadedBy"],
      schema: postSchema,
      name: "Posts",
      permissions: ({ user }) => ({
        edit: true,
        create: true,
        delete: true,
      }),
    }),
    buildCollection({
      relativePath: "documents",
      schema: documentSchema,
      name: "Documents",
      permissions: ({ user }) => ({
        edit: true,
        create: true,
        delete: true,
      }),
    }),
  ],
});
