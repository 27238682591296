import { buildSchema } from "@camberi/firecms";
import { ConversationDocumentData } from "../models/conversation_model";

export const conversationSchema = buildSchema<Partial<ConversationDocumentData>>({
  name: "Conversation",
  properties: {
    home: {
      title: "Home",
      dataType: "reference",
      collectionPath: "homes",
      validation: {
        required: true,
      },
      // TODO: preview communityData.name and communityData.divisionData.name?
      previewProperties: ["streetAddress", "communityData"],
    },
    homebuyers: {
      title: "Homebuyers",
      description: "The homebuyers in this conversation",
      dataType: "array",
      of: {
        dataType: "reference",
        collectionPath: "homebuyers",
        previewProperties: ["displayName"],
      },
    },
    admins: {
      title: "Admins",
      description: "The William Ryan Homes representatives in this conversation",
      dataType: "array",
      of: {
        dataType: "reference",
        collectionPath: "admins",
        previewProperties: ["displayName"],
      },
    },
    lastMessageAt: {
      readOnly: true,
      title: "Last Message At",
      dataType: "timestamp",
    },
    // unreads: {
    //   dataType: "map",
    //   description: "Number of new messages for by recipient", // in the format of { uid: count }
    //   readOnly: true,
    // }
  }
});