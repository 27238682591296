import { buildCollection } from "@camberi/firecms";
import { warrantyDocumentSchema } from "../schemas/warranty_document_schema";

export const warrantyDocumentCollection = buildCollection({
  relativePath: "warrantyDocuments",
  schema: warrantyDocumentSchema,
  properties: ["fileName", "file"],
  group: "Content",
  name: "Warranty Documents",
  permissions: ({ user }) => ({
    edit: true,
    create: true,
    delete: true,
  }),
});
