import { buildSchema } from "@camberi/firecms";

export const divisionSchema = buildSchema({
  name: "Division",
  properties: {
    name: {
      title: "Name",
      validation: { required: true },
      dataType: "string",
    },
  },
});