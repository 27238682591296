import { buildSchema } from "@camberi/firecms";
import { AdminDocumentData, AdminRole } from "../models/admin_model";

export const adminSchema = buildSchema<Partial<AdminDocumentData>>({
  name: "Admin",
  properties: {
		displayName: {
			title: "Name",
			dataType: "string",
		},
		email: {
			title: "Email",
			dataType: "string",
			validation: { email:true },
		},
		phoneNumber: {
			title: "Phone Number",
			dataType: "string",
		},
    photoURL: {
      title: "Photo",
      dataType: "string",
      config: {
        url: "image",
      },
			validation: { url:true },
    },
    roles: {
      title: "Roles",
      dataType: "array",
			of: {
				dataType: "string",
				config: {
					previewAsTag: true,
					enumValues: {
						"super": "Super Admin",
						"corp": "Corporate Admin",
						"rep": "Sales Representative",
					},
				},
			},
    },
		divisions: ({ values }) => ({
			title: "Divisions",
			description: "Limit permissions to specific divisions",
			dataType: "array",
      of: {
        dataType: "reference",
        collectionPath: "divisions",
        previewProperties: ["name"]
      },
			disabled: values?.roles?.includes(AdminRole.rep) && 
                !values?.roles?.includes(AdminRole.corp) && 
                !values?.roles?.includes(AdminRole.super) && 
      {
				clearOnDisabled: true,
				disabledMessage: "Only Corporate Admins can be limited to specific divisions",
			},
		}),
    communities: ({ values }) => ({
      title: "Communities",
			description: "Limit permissions to specific communities",
      dataType: "array",
      of: {
        dataType: "reference",
        collectionPath: "communities",
        previewProperties: ["name", "division"]
      },
			disabled: values?.roles?.includes(AdminRole.corp) && 
                !values?.roles?.includes(AdminRole.rep) && 
                !values?.roles?.includes(AdminRole.super) && 
      {
				clearOnDisabled: true,
				disabledMessage: "Only Sales Representatives can be limited to specific communities",
			},
    }),
  }
});