import { AlgoliaTextSearchDelegate } from "@camberi/firecms";
import algoliasearch from "algoliasearch";

export const algoliaClient = algoliasearch(
  "4XRZ8Q6CEH",
  "b9dfc911689b616a6320c0dd40f436b8"
);

export const searchDelegate = (index: string) => AlgoliaTextSearchDelegate(
  algoliaClient,
  index,
);

export const divisionSearchDelegate = AlgoliaTextSearchDelegate(
  algoliaClient,
  "divisions"
);

export const communitySearchDelegate = AlgoliaTextSearchDelegate(
  algoliaClient,
  "communities"
);

export const homeSearchDelegate = AlgoliaTextSearchDelegate(
  algoliaClient,
  "homes"
);

export const homebuyerSearchDelegate = AlgoliaTextSearchDelegate(
  algoliaClient,
  "homebuyers"
);

export const adminSearchDelegate = AlgoliaTextSearchDelegate(
  algoliaClient,
  "admins"
);

export const conversationSearchDelegate = AlgoliaTextSearchDelegate(
  algoliaClient,
  "conversations"
);

export const messageSearchDelegate = AlgoliaTextSearchDelegate(
  algoliaClient,
  "messages"
);