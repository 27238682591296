import { buildSchema } from "@camberi/firecms";
import "firebase/auth";
import "firebase/firestore";
import { ReceiptDocumentData } from "../models/receipt_model";

export const receiptSchema = buildSchema<Partial<ReceiptDocumentData>>({
  name: "Receipt",
  description: "Read receipt",
  properties: {
    homebuyer: {
      dataType: "reference",
      collectionPath: "homebuyers",
      previewProperties: ["displayName"],
    },
    readAt: {
      title: "Read At",
      readOnly: true,
      dataType: "timestamp",
    },
  },
});